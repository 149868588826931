<template>
  <div class="article_pannel">
    <div class="article_card" v-html="content"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      content: "",
    };
  },
  mounted() {
    this.getInfo();
  },
  methods: {
    getInfo() {
      this.$store.dispatch("article/selectAboutUs").then((res) => {
        var { data } = res;
        this.content = data.content;
      });
    },
  },
};
</script>

<style scoped>
.article_pannel {
  padding: 10vh 10vh 0vh 10vh;
  height: 80vh;
  background-color: #4c4c4c;
}
.article_card {
  margin: auto;
  padding: 0 1rem;
  border-radius:10px;
  overflow-y: auto;
  height: 80vh;
  width: 90vw;
  background-color: #fff;
}
.article_card::-webkit-scrollbar {
  display: none;
}
</style>